import { IconButton, TextField } from "@mui/material"
import { DateTimeValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { FormItemStyled, FormStyled } from "fwork.react.apptemplate"
import moment from "moment"
import React from "react"
import { FaTrash } from "react-icons/fa"
import { IPrizeDrawOffPrice } from "rifa.lib.common.ts/models"
import { DateTimePickerExtComponent } from "../../../common/components/datePicker"
import { DEFAULTGAP } from "../../globals"

export const PrizeDrawOffPriceComponent = (props: {
  offPrice?: IPrizeDrawOffPrice,
  onChange?: (prizeDrawOffPrice: IPrizeDrawOffPrice,) => void,
  onTrashClick?: () => void,
  disabled: boolean
}) => {
  let offPrice: IPrizeDrawOffPrice = {
    ...props.offPrice,
  } as IPrizeDrawOffPrice


  return <div style={{ border: '1px solid #C4C4C4', display: 'flex', justifyContent: 'space-between', background: 'white', padding: DEFAULTGAP, borderRadius: 10 }}>
    <FormStyled >
      <FormItemStyled>
        <TextField disabled={props.disabled} label='Preço promocional'
          style={{
            background: 'white'
          }}
          type='number'
          value={offPrice?.price?.toString() || ''}
          onChange={(e) => {
            offPrice.price = Number(e.target.value)
            if (props.onChange && offPrice)
              props.onChange(offPrice)
          }}
        />
      </FormItemStyled>


      <FormItemStyled>
        <TextField
          disabled={props.disabled}
          style={{
            background: 'white'
          }}
          label='Quantidade mínima de cotas'
          type='number'
          value={offPrice?.from?.toString() || ''}
          onChange={(e) => {
            offPrice.from = Number(e.target.value)
            if (props.onChange && offPrice)
              props.onChange(offPrice)
          }}
        />
      </FormItemStyled>

      <FormItemStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePickerExtComponent
            disabled={props.disabled}
            // InputProps={{
            //   style: {
            //     background: 'white'
            //   }
            // }}
            ampm={false}
            // inputFormat='DD/MM/YYYY HH:mm'
            label="Data inicial"
            // renderInput={(params) => <TextField {...params} />}
            value={moment(offPrice?.initDateTime)}
            // onChange={function (value: any, keyboardInputValue?: string | undefined): void {
            onChange={(value: moment.Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
              // UTC ISO
              offPrice.initDateTime = moment(value).toISOString()
              console.log(offPrice.initDateTime)
              if (props.onChange && offPrice)
                props.onChange(offPrice)
            }}
          />
        </LocalizationProvider>
      </FormItemStyled>

      <FormItemStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePickerExtComponent
            disabled={props.disabled}
            // InputProps={{
            //   style: {
            //     background: 'white'
            //   }
            // }}
            ampm={false}
            // inputFormat='DD/MM/YYYY HH:mm'
            label="Data final"
            // renderInput={(params) => <TextField {...params} />}
            value={moment(offPrice?.endDatetime)}
            // onChange={function (value: any, keyboardInputValue?: string | undefined): void {
            onChange={(value: moment.Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
              // UTC ISO
              offPrice.endDatetime = moment(value).toISOString()
              if (props.onChange && offPrice)
                props.onChange(offPrice)
            }}
          />
        </LocalizationProvider>
      </FormItemStyled>
    </FormStyled>

    {props.disabled ? <></> : <IconButton style={{ height: 46, width: 46 }}><FaTrash style={{ cursor: 'pointer' }} color='grey' size={20} onClick={props.onTrashClick} /></IconButton>} 

  </div>
}