import React from "react"
import { DefaultPageComponent } from "../../components/defaultPage"
import { HomeComponent } from "../../components/home"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

export const MainPage = () => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)

  return <>
    <DefaultPageComponent
      showTutorial={true}
      hideFlexGrow={true}
      appBarOptions={{
        showSearch: settingsState.payload?.[0]?.showSearchBar,
      }}
      content={<HomeComponent />}
    />
  </>
}