import { Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material"
import { ApiClientUtils } from "fwork-jsts-common/src/apiClient"
import { authenticationStateSet, FlexGrowComponent, FormItemStyled, FormStyled, IAuthenticationState, SelectImgComponent, TextPwdComponent } from "fwork.react.apptemplate"
import { FilterQuery } from "mongoose"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IPrizeDraw, IUser, UserTypes } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"
import { UsersApiClient } from "../../../apiClient/users"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from "../miscelaneous/header"
import { MdOutlineContentCopy } from "react-icons/md"

const ImgWrapper = styled(FormItemStyled)(({ theme }) => ({
  width: '100% !important',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start'
  }
}))

export const UserComponent = (props: {
  data: IUser,
  onConfirm?: () => void,
  onCancel?: () => void,
  onDelete?: () => void,
}) => {
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const [data, setData] = useState<IUser>(props.data)
  const { enqueueSnackbar } = useSnackbar()
  const [confirmedPwd, setConfirmedPwd] = useState<string>()
  const dispatch = useDispatch<any>()

  const [localPrizeDraws, setLocalPrizeDraws] = useState<IPrizeDraw[] | undefined>()
  const [selectedPrizedraw, setSelectedPrizedraw] = useState<any>();
  const [prizedrawShareUrl, setPrizedrawShareUrl] = useState<string>();

  useEffect(() => {
    if (props.data)
      setData(props.data)

    loadPrizedrawsData()
  }, [props.data])

  useEffect(() => {
    if (selectedPrizedraw) {
      var urlAddon = selectedPrizedraw.friendlyUrl ? selectedPrizedraw.friendlyUrl : `prizedraw/${selectedPrizedraw._id}`
      setPrizedrawShareUrl(`http://${window.location.host}/${urlAddon}/${data.affiliateCode}`)
    }
  }, [data.affiliateCode, selectedPrizedraw]);

  const confirm = async () => {
    if (data.pwd != confirmedPwd)
      return

    const apiResult = await (data._id ? new UsersApiClient(axios).put({
      data,
    }) : new UsersApiClient(axios).post({
      data,
    }))

    if (apiResult.success) {
      if (apiResult.data)
        setData(apiResult.data)

      enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' })

      if (authenticationState.payload?.user._id == data._id) {
        if (apiResult.data && authenticationState.payload?.token) {
          const tmpAuthState: IAuthenticationState<IUser> = {
            ...authenticationState,
            payload: {
              ...authenticationState.payload,
              user: apiResult.data as IUser
            }
          }

          dispatch(authenticationStateSet(tmpAuthState))
        }

      }
      if (props.onConfirm)
        props.onConfirm()

      setConfirmedPwd(undefined)
    }
    else {
      enqueueSnackbar(apiResult.msg, { variant: 'error' })
    }
  }

  const loadPrizedrawsData = async () => {
    try {
      let where: FilterQuery<IPrizeDraw> | undefined

      const response = await new PrizeDrawsApiClient(axios).get({
        where
      })

      if (response.success) {
        setLocalPrizeDraws(response.data?.payload)
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title={<>
        <h3>
          {`Usuário ${data?.name || ''}, `}
        </h3>
        <h3 style={{
          color: 'grey', marginLeft: 10
        }}>
          {data?.login?.toString()}
        </h3>
      </>}

      onConfirmClick={confirm}
      onCancelClick={props.onCancel}
    />

    <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <ImgWrapper>
        <SelectImgComponent
          id="userImgPicker"
          onSelectImg={(src: string) => {
            setData({
              ...data,
              imgProfileUrl: src
            })
          }}
          imgProps={{
            src: data.imgProfileUrl?.toUpperCase().indexOf(';BASE64,') != -1 ? data.imgProfileUrl?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${data.imgProfileUrl?.toString()}`,
            style: {
              width: 200,
              height: 200,
              borderRadius: '100%',
              objectFit: 'cover',
            }
          }} />
      </ImgWrapper>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Nome'
          // nao usar !=== em "data._id != null"
          disabled={data.name == 'root' && data._id != null}
          value={data?.name?.toString() || ''}
          onChange={(e) => setData({
            ...data,
            name: e.target.value
          })}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Login'
          value={data?.login?.toString() || ''}
          onChange={(e) => setData({
            ...data,
            login: e.target.value
          })}
        />
      </FormItemStyled>

      <div style={{ width: '100%' }}></div>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        justifyContent: 'flex-end',
        width: 'auto'
      }}>
        <FormControlLabel label='Administrador' control={
          <Checkbox
            disabled={data.name == 'root'}
            checked={data?.userTypes?.includes(UserTypes.admin) ?? false}
            onChange={(e) => setData({
              ...data,
              userTypes: e.target.checked ? [...new Set([...data.userTypes ?? [], UserTypes.admin])] : data.userTypes?.filter(ut => ut != UserTypes.admin)
            })} />
        }></FormControlLabel>
      </FormItemStyled>

      {/* <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        justifyContent: 'flex-end',
        width: 'auto'
      }}>
        <FormControlLabel label='Cambista' control={
          <Checkbox
            checked={data?.userTypes?.includes(UserTypes.seller) ?? false}
            onChange={(e) => setData({
              ...data,
              userTypes: e.target.checked ? [...new Set([...data.userTypes ?? [], UserTypes.seller])] : data.userTypes?.filter(ut => ut != UserTypes.seller)
            })}
          />
        }></FormControlLabel>
      </FormItemStyled> */}

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        justifyContent: 'flex-end',
        width: 'auto'
      }}>
        <FormControlLabel label='Afiliado' control={
          <Checkbox
            checked={data?.userTypes?.includes(UserTypes.affiliate) ?? false}
            onChange={(e) => setData({
              ...data,
              userTypes: e.target.checked ? [...new Set([...data.userTypes ?? [], UserTypes.affiliate])] : data.userTypes?.filter(ut => ut != UserTypes.affiliate)
            })}
          />
        }></FormControlLabel>
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        justifyContent: 'flex-end',
        width: 'auto'
      }}>
        <FormControlLabel label='Supervisor' control={
          <Checkbox
            checked={data?.userTypes?.includes(UserTypes.affiliateAdmin) ?? false}
            onChange={(e) => setData({
              ...data,
              userTypes: e.target.checked ? [...new Set([...data.userTypes ?? [], UserTypes.affiliateAdmin])] : data.userTypes?.filter(ut => ut != UserTypes.affiliateAdmin)
            })}
          />
        }></FormControlLabel>
      </FormItemStyled>

    </FormStyled>

    <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      {data._id ? <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextPwdComponent
          props={{
            autoComplete: 'new-password',
            label: 'Para alterar a senha entre com a senha anterior',
            value: data?.oldPwd?.toString() || '',
            onChange: (e) => setData({
              ...data,
              oldPwd: e.target.value
            }),
          }}
        />
      </FormItemStyled> : <></>}

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextPwdComponent
          props={{
            autoComplete: 'new-password',
            fullWidth: true,
            label: data._id ? 'Insira a nova senha' : 'Senha',
            onChange: (e) => {
              setData({
                ...data,
                pwd: e.target.value
              })
            },
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextPwdComponent
          props={{
            autoComplete: 'new-password',
            fullWidth: true,
            label: 'Confirme a nova senha',
            onChange: (e) => {
              setConfirmedPwd(e.target.value)
            },
            error: data.pwd != confirmedPwd,
            helperText: data.pwd != confirmedPwd ? 'As senhas não conferem' : null
          }}
        />
      </FormItemStyled>

    </FormStyled>

    {/* AFFILIATE */}
    <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label="Código Afiliado"
          type="text"
          value={data?.affiliateCode ?? ''}
          onChange={(e) => {
            setData({
              ...data,
              affiliateCode: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField
          label="Taxa Comissão Afiliado"
          type="number"
          value={data?.affiliateComissionValue?.toString() ?? ''}
          onChange={(e) => {
            setData({
              ...data,
              affiliateComissionValue: parseFloat(e.target.value)
            })
          }}
        />
      </FormItemStyled>
    </FormStyled>


    {/*  SELECT - SHARE URL */}
    <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{ flexDirection: 'column' }}>
      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel
            id="select-label"
            shrink
          >
            Gerar Url Afiliado
          </InputLabel>
          <Select
            label="Gerar Url Afiliado"
            labelId="select-label"
            value={selectedPrizedraw?._id || ''}
            onChange={(event) => {
              const prizedraw = localPrizeDraws?.find(item => item._id === event.target.value as string);
              setSelectedPrizedraw(prizedraw);
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              sorteios...
            </MenuItem>
            {localPrizeDraws?.map(option => (
              <MenuItem key={option._id} value={option._id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormItemStyled>

      <FormItemStyled fullWidth>
        <div style={{ display: 'flex' }}>
          {selectedPrizedraw && (
            <label style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: DEFAULTGAP,
              padding: DEFAULTGAP,
              background: 'silver',
              borderRadius: 5,
            }}>
              {prizedrawShareUrl}
              <IconButton style={{ marginLeft: 10 }}>
                <MdOutlineContentCopy onClick={() => {
                  if (prizedrawShareUrl?.length) {
                    enqueueSnackbar('Url do afiliado copiada', { variant: "success" })
                    navigator.clipboard.writeText(prizedrawShareUrl)
                  }
                }} />
              </IconButton>
            </label>
          )}
          <FlexGrowComponent />
        </div>
      </FormItemStyled>
    </FormStyled>
  </>
}