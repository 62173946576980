import { Checkbox, FormControlLabel, styled, TextField } from "@mui/material"
import { ApiClientUtils } from "fwork-jsts-common/src/apiClient"
import { FlexGrowComponent, FormItemStyled, FormStyled, SelectImgComponent } from "fwork.react.apptemplate"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ISetting, ITheme } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { settingsStateLoadFromApi, settingsStateSet } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from "../miscelaneous/header"
import { SettingsAdditionalContainerComponent } from "./additional"

const ImgWrapper = styled(FormItemStyled)(({ theme }) => ({
  width: '100% !important',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start'
  }
}))

export const SettingsComponent = (props: {
  data?: ISetting,
  onConfirm?: () => void,
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  let [data, setData] = useState<ISetting | undefined>(props.data)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch<any>()

  console.log('SettingsComponent');

  useEffect(() => {
    console.log('SettingsComponent: useEffect');
    setData(props.data)
  }, [props.data])

  const confirm = async () => {
    const dataTmp: ISetting = {
      ...data,
      tutorials: data?.tutorials?.filter(t =>
        !([undefined, null, ''].indexOf(t.text?.trim().toString() || '') !== -1) ||
        !([undefined, null, ''].indexOf(t.title?.trim().toString() || '') !== -1))
    }

    const apiResult = await (dataTmp?._id ? ApiClientUtils.put({
      axios,
      apiUrl: `${dotEnvProductionState.payload?.APIURL}/settings`,
      data: dataTmp
    }) : ApiClientUtils.post({
      axios,
      apiUrl: `${dotEnvProductionState.payload?.APIURL}/settings`,
      data: dataTmp
    }))
    if (apiResult.success) {
      enqueueSnackbar('Alterações salvas com sucesso', { variant: "success" })
      dispatch(settingsStateLoadFromApi({}))
      if (props.onConfirm)
        props.onConfirm()
    }
    else {
      enqueueSnackbar(apiResult.msg, { variant: 'error' })
    }
  }

  return <>
    <HeaderComponent
      title='Configurações'
      onConfirmClick={confirm}
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
    />

    <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
      <ImgWrapper style={{ gap: 10 }}>
        <label>Logo da empresa</label>
        <SelectImgComponent
          id="settingsLogoPicker"
          onSelectImg={(src: string) => {
            setData({
              ...data,
              companyLogoUrl: src
            })
          }}
          imgProps={{
            src: data?.companyLogoUrl?.indexOf(';base64,') != -1 ? data?.companyLogoUrl?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${data.companyLogoUrl?.toString()}`,
            style: {
              width: 300,
              height: 200,
              borderRadius: 8,
              objectFit: 'contain',
            }
          }} />
      </ImgWrapper>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Whatsapp'
          defaultValue={data?.whatsapp?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              whatsapp: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Instagram'
          defaultValue={data?.instagram?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              instagram: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Facebook'
          defaultValue={data?.facebook?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              facebook: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <TextField label='Twitter'
          defaultValue={data?.twitter?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              twitter: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        width: '100%'
      }}>
        <TextField multiline
          maxRows={10}
          label='Política de cookies'
          defaultValue={data?.cookiePolicy?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              cookiePolicy: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        width: '100%'
      }}>
        <TextField multiline
          maxRows={10}
          label='Política de privacidade'
          defaultValue={data?.privacyPolicy?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              privacyPolicy: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        width: '100%'
      }}>
        <TextField multiline
          maxRows={10}
          label='Termos de uso'
          defaultValue={data?.termsOfUse?.toString() || ''}
          onChange={(e) => {
            setData({
              ...data,
              termsOfUse: e.target.value
            })
          }}
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir caminhos do site'
          control={
            <Checkbox
              checked={data?.showSitePath ?? false}
              onChange={(e) => {
                setData({
                  ...data,
                  showSitePath: e.target.checked
                })
              }}
            />
          }
        />
      </FormItemStyled>

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}
        style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
        <FormControlLabel
          label='Exibir barra de pesquisa'
          control={
            <Checkbox
              checked={data?.showSearchBar ?? false}
              onChange={(e) => {
                setData({
                  ...data,
                  showSearchBar: e.target.checked
                })
              }}
            />
          }
        />
      </FormItemStyled>
    </FormStyled>

    {data ? <SettingsAdditionalContainerComponent
      tutorialsProps={{
        tutorials: data?.tutorials || [],

        onChange: (tutorials) => {
          if (data) {
            const dataTmp = { ...data }
            dataTmp.tutorials = tutorials
            setData({
              ...dataTmp
            })
          }
        },

        onDelete: (idx) => {
          let dataTmp: ISetting = {
            ...data,
            tutorials: [...data?.tutorials || []]
          }
          dataTmp?.tutorials?.splice(idx, 1)
          setData({
            ...dataTmp
          })
        }
      }}

      bannersProps={{
        banners: data?.banners || [],

        onChange: (banners) => {
          if (data) {
            const dataTmp = { ...data }
            dataTmp.banners = banners
            setData({
              ...dataTmp
            })
          }
        },

        onDelete: (idx) => {
          let dataTmp: ISetting = {
            ...data,
            banners: [...data?.banners || []]
          }
          dataTmp?.banners?.splice(idx, 1)
          setData({
            ...dataTmp
          })
        }
      }}

      financeProps={{
        setting: data,

        onChange: (setting: ISetting) => {
          setData(setting)
        }
      }}

      themeProps={{
        theme: data.theme,
        onChange: (theme: ITheme) => {
          setData({
            ...data,
            theme,
          })

          // dispatch(settingsStateSet(data ? [data] : []))
        }
      }}
    /> : <></>}

    <FlexGrowComponent />
  </>
}