export const ApiRoutesNames = {
  img: '/img',
  authentication: '/auth',
  customers: '/customers',
  prizedrawProviders: '/prizedraw-providers',
  prizedraws: '/prizedraws',
  unavailableNumbers: '/prizedraws/:id/unavailable-numbers',
  unavailableNumbersLength: '/prizedraws/:id/unavailable-numbers-length',
  prizedrawsCustomerRankingByQuoteCount: '/prizedraws/:id/customer-ranking-by-quotecount',
  settings: '/settings',
  tickets: '/tickets',
  ticketsPayments: '/tickets/:id/payments',
  tickets_paymentWebHook_updateTransaction: '/tickets/payment-wh-update-transaction',
  ticketsRenewNumbersConfirm: '/tickets/renew/confirm/:token',
  ticketsRenewNumbers: '/tickets/renew/:id',
  ticketsWinners: '/tickets/winners',
  ticketsWinnersAwardedNumbers: '/tickets/winners-awarded-numbers',
  users: '/users',
  transactions_sl7payWebHook_update: '/transactions/sl7paywebhook-update',
  affiliate_commissions: '/affiliate-commissions',
  system: '/system',
  sync: '/sync'
}
