import moment from "moment"
import React from "react"
import { IPrizeDrawAwardedNumber } from "rifa.lib.common.ts/models"
import { DEFAULTGAP } from "../../globals"
import { HeaderComponent } from "../miscelaneous/header"
import { PrizeDrawAwardedNumberComponent } from "../prizeDrawAwardedNumber"

export const PrizeDrawAwardedNumbersComponent = (props: {
  awardedNumbers: IPrizeDrawAwardedNumber[],
  onChange?: (offPrices: IPrizeDrawAwardedNumber[]) => void,
  onDelete?: (idx: number) => void
  disabled?: boolean
}) => {
  let awardedNumbers: IPrizeDrawAwardedNumber[] = [...props.awardedNumbers]

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    padding: DEFAULTGAP,
    gap: DEFAULTGAP,
    background: '#F9F9F9'
  }} >
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP,
        }
      }}
      title={'Cotas Premiadas'}
      onAddClick={props.disabled ? undefined : () => {
        const awardedNumbersTemp = [...awardedNumbers]
        awardedNumbersTemp.unshift({
          validFromDateUnix: moment().unix(),
        } as IPrizeDrawAwardedNumber)
        if (props.onChange)
          props.onChange(awardedNumbersTemp)
      }} />

    {awardedNumbers?.map((awarded, idx) => <PrizeDrawAwardedNumberComponent
      disabled={props.disabled}
      onTrashClick={() => {
        if (props.onDelete)
          props.onDelete(idx)
      }}
      key={idx} awardedNumber={awarded} onChange={(pAwarded) => {
        const awardedNumbersTemp = [...awardedNumbers]
        awardedNumbersTemp[idx] = pAwarded
        if (props.onChange)
          props.onChange(awardedNumbersTemp)
      }} />)}
  </div>
}